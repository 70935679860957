import styles from '../css/DashBoard.module.css'

function DashBoard() {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <div>
                <h1 className={styles.copy_right}>Under development</h1>
                <span className={styles.copy_right}> Crypto Simulator AI &copy; {currentYear}</span>
            </div>

        </>
    )
}

export default DashBoard
