
import axios from 'axios';
import React, { createContext, useState, useContext } from 'react';
import {getApiLogin} from '../utils/Api'


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);

  const login = async (username, password) => {
    try {
      const response = await axios.post(getApiLogin(), {
        username,
        password,
      });
      setAuth(response.data.token);
      return response.data.token;
    } catch (error) {
      console.error('Erro ao fazer login', error);
      throw error;
    }
  };

  const logout = () => {
    setAuth(null);
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
    
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
