import styles from '../css/PrivacyPolicy.module.css'

function PrivacyPolicy() {
    return (

        <div className={styles.text_center}>
            <header>
                <h1>Terms and Conditions</h1>
                <p>Last updated on March 18, 2024</p>
            </header>

            <section>
                <span >Thank you for being a part of the Crypto Simulator AI community provided and operated by WestCloud Solution, located in Brazil. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices regarding your personal information, please contact our data privacy officer at <a href="mailto:privacy@cryptosimulatorai.com">privacy@cryptosimulatorai.com</a> or by mail at the following address:</span >

                <address>
                    Street: Tapajos 450, Bairro: Universitario, Chapeco/SC, Brazil
                </address>
                <span >When you visit our website <a href="http://cryptosimulatorai.com"> https://cryptosimulatorai.com </a> (the "Website"), use our mobile app, as applicable (the "App"), and more generally, use any of our services (the "Services," which include the Website and App), we appreciate you trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have concerning it. We hope you take some time to read it carefully as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue the use of our Services immediately.</span >
                <p>This privacy notice applies to all information collected through our Services, as well as any sales, marketing, or related events.</p>
                <h2>Please read this privacy notice carefully as it will help you understand what we do with the information we collect.</h2>
            </section>

            <section id="section-1">
                <h2>1. What Information Do We Collect?</h2>

                <article>
                    <h3>Personal information you provide to us</h3>
                    <p>We collect personal information you voluntarily provide to us when registering for the Services, expressing an interest in obtaining information about our Services, participating in activities on the Services, or otherwise when you contact us.</p>

                    <p>The personal information we collect may include the following: names, phone numbers, email addresses, locations, and other similar contact information.</p>

                    <p>Since you have provided us with this data voluntarily, this processing is lawful based on your consent. You may withdraw your consent at any time. Withdrawal of consent does not affect the lawfulness of data processing conducted before withdrawal. We primarily use this data to contact you, verify the authenticity of the data you provided us, your compliance with our terms and policies, and all applicable regulatory and legal requirements in any relevant jurisdiction.</p>
                </article>

                <article>
                    <h3>Payment Data</h3>
                    <span >All payment data is collected and stored by Google Play and the Apple App Store. You can find their privacy notice link(s) here: <a href="https://policies.google.com/privacy">Google Play's Privacy Policy</a> and <a href="https://www.apple.com/legal/privacy">Apple's Privacy Policy</a>. After your payment, we are informed about the product or service you purchased, your country, and the amount paid. No other information is provided to us about any of your purchases.</span >
                </article>

                <article>
                    <h3>Automatically collected information</h3>
                    <p>We automatically collect certain information when you visit, use, or browse the Services. This information does not reveal your specific identity (such as your name or contact information) but may include information about the device and usage, such as your IP address, browser and device characteristics (such as types and versions), operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services (such as timestamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings). This information is primarily needed to maintain the security and operation of our Services, improve our Services continuously and offer you a more pleasant and efficient experience, and for our internal analytics and reporting purposes, which is our legitimate interest in data processing.</p>
                </article>

                <article>
                    <h3>Information collected through the App</h3>
                    <ul>
                        <li><strong>Mobile Device Access:</strong> We may request access or permission to certain features from your mobile device, including your mobile device's camera and other features. If you wish to change our access or permissions, you may do so in your device settings.</li>
                        <li><strong>Mobile Device Data:</strong> We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system information, version information and system configuration, device and application identification numbers, browser type and version, hardware model, internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the telephone network associated with your mobile device, the operating system or platform of your mobile device, the type of mobile device you use, the unique device ID of your mobile device, and information about the features of our App you accessed.</li>
                        <li><strong>Push Notifications:</strong> We may request to send you push notifications about your account or certain App features. If you wish to opt out of these types of communications, you can turn them off in your device settings.</li>
                    </ul>

                    <p>This information is primarily necessary to maintain the security and operation of our Services, improve our Services continuously and offer you a more pleasant and efficient experience, and for our internal analytics and reporting purposes, which is our legitimate interest in data processing.</p>
                </article>
            </section>

            <section id="section-2">
                <h2>2. How Do We Use Your Data?</h2>

                <p>In summary: We process your data for purposes based on legitimate business interests, the fulfillment of our Services, compliance with our legal obligations, and/or your consent. We use the information we collect or receive in the following ways:</p>

                <ul>
                    <li><strong>Facilitate account creation and login process:</strong> If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and login process.</li>
                    <li><strong>To enable communications:</strong> We may use your information to communicate with you, request feedback, and notify you about new features or information.</li>
                    <li><strong>To protect our Services:</strong> We may use your information as part of our efforts to keep our Services safe and secure (e.g., for fraud monitoring and prevention).</li>
                    <li><strong>To respond to legal requests and prevent harm:</strong> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond or to enforce our terms, conditions, and policies for business purposes or to comply with legal and regulatory requirements.</li>
                    <li><strong>To assist your requested activities or subscriptions:</strong> We may use your information to assist your requested activities or subscriptions, some of which may require integrations and sharing your data with third parties that provide these services — Google Pay, Apple Pay, news feeds, cryptocurrency services, promotions, advertisements, etc.</li>
                    <li><strong>For other business purposes:</strong> We may use your information for other business purposes such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Services, products, marketing, and your experience.</li>
                </ul>

                <p>We may use and store this data in aggregated and anonymized forms so that it is not associated with individual users and does not include personal information. Our Services may contain advertising, promotions, or marketing on an aggregated basis. We will not use personally identifiable information without your consent.</p>
            </section>

            <section id="section-3">
                <h2>3. Will Your Data Be Shared With Anyone?</h2>

                <p>With the exceptions described in this section, we do not make your personal data available to third parties unless you have expressly consented, we are legally required to do so, or it is necessary to enforce our rights concerning a contractual relationship.</p>

                <p>We may grant access to personal data about you:</p>

                <ul>
                    <li>To public entities and institutions (e.g., regulatory authorities, quasi-regulatory authorities, tax or other authorities, law enforcement agencies, courts, arbitration bodies, fraud prevention agencies)</li>
                    <li>To other credit and financial service institutions or comparable institutions to establish a business relationship</li>
                    <li>To third parties in connection with transactions they are involved in (e.g., correspondent banks, brokers, exchanges, processing units, and third-party custodians, issuers, investors, potential buyers, and other transaction participants and their representatives)</li>
                    <li>To potential buyers as part of a sale, merger, or other disposition of any of our businesses or assets</li>
                    <li>In compliance with legal requirements in connection with the fight against fraud, money laundering, terrorism, and other financial crimes</li>
                </ul>

                <p>We may share personal data with service providers we have engaged to assist us in providing services to you, such as:</p>

                <ul>
                    <li>IT and communications service providers</li>
                    <li>Payment processors, including banks, which process your transactions</li>
                    <li>Analytics and search engine providers that assist us in the improvement and optimization of our website</li>
                    <li>Other service providers necessary to facilitate the proper operation of our Services</li>
                </ul>

                <p>We may also share or transfer your data in connection with or during negotiations of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>

                <p>We may use cookies and similar tracking technologies (web beacons, pixels, mobile analytics software, etc.) to access or store information. We may share or transfer personal data with our group companies or affiliates to carry out our day-to-day business operations and offer you more efficient, precise, and better quality Services. This privacy notice applies to all such uses of personal data by our group companies and affiliates. You can find out more about how we use cookies in our Cookies Policy.</p>

                <p>We will retain your data for as long as your account remains active or as needed to provide you services, comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
            </section>

            <section id="section-4">
                <h2>4. What is our stance on third-party websites?</h2>

                <p>In short: We are not responsible for the safety of any information that you share with third-party providers who advertise but are not affiliated with our Website.</p>

                <p>The Services may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services, or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.</p>
            </section>

            <section id="section-5">
                <h2>5. How long do we keep your information?</h2>

                <p>In summary: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>

                <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>

                <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            </section>

            <section id="section-6">
                <h2>6. How do we keep your information safe?</h2>

                <p>In summary: We aim to protect your personal information through a system of organizational and technical security measures.</p>

                <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, the transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
            </section>

            <section id="section-7">
                <h2>7. What are your privacy rights?</h2>

                <p>In some regions (like the European Economic Area and the United Kingdom), you have certain rights under applicable data protection laws. These may include the right to:</p>

                <ul>
                    <li>Request access and obtain a copy of your personal information</li>
                    <li>Request rectification or erasure</li>
                    <li>Restrict the processing of your personal information</li>
                    <li>If applicable, data portability</li>
                </ul>

                <p>In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided in the "Contact Us" section below. We will consider and act upon any request per applicable data protection laws.</p>

                <span> If you are a resident of the European Economic Area or the United Kingdom and believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</span>

                <span > If you are a resident of Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</span >

                <span > If you have questions or comments about your privacy rights, you may email us at <a href="mailto:privacy@cryptosimulatorai.com">privacy@cryptosimulatorai.com</a>.</span >
            </section>

            <section id="section-8">
                <h2>8. How can you contact us about this privacy notice?</h2>

                <span >If you have questions or comments about this notice, you may email us at <a href="mailto:privacy@cryptosimulatorai.com">privacy@cryptosimulatorai.com</a> or by post to:</span >

                <address>
                    Crypto Simulator AI
                    Street: Tapajos 450, Bairro: Universitario, Chapeco/SC, Brazil
                </address>

                <p>We will respond to your request as soon as possible and usually within 30 days.</p>
            </section>
        </div>
    )

}

export default PrivacyPolicy
