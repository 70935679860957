import { useState, useEffect } from 'react'
import CryptoInfo from '../components/CryptoInfo';
import CookieConsentModal from '../components/CookieConsentModal';

function Market() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

   return (
        <div>
            <CryptoInfo />
            <CookieConsentModal />
        </div>
    )

}

export default Market