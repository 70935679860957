import styles from '../css/PrivacyPolicy.module.css'

function termsOfService() {
    return (

        <div className={styles.text_center}>

            <header>
                <h1>Terms and Conditions</h1>
                <p>Last updated on March 18, 2024</p>
            </header>

            <section>
                <h2>Acceptance of Terms</h2>
                <p>
                    These Terms and Conditions ("Terms") govern your relationship with the Crypto Simulator AI (the "Service")
                    provided by WestCloud Solution, located in Brazil. Please read these Terms carefully before using the
                    Service. Your access to and use of the Service is conditioned on your acceptance of and compliance with
                    these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By
                    accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of
                    these Terms, you may not access the Service.
                </p>
            </section>

            <section>
                <h2>License to Use</h2>
                <p>
                    If you access the Service through a mobile application, we grant you a revocable, non-exclusive,
                    non-transferable, and limited right to install and use the mobile application on wireless electronic
                    devices that you own or control and to access and use the mobile application on such devices strictly in
                    accordance with the terms and conditions of this mobile application license contained in these Terms. You
                    must not:
                </p>
                <ul>
                    <li>Decompile, reverse engineer, disassemble, copy, store, or attempt to derive the source code of, or decrypt the application, content, or Service.</li>
                    <li>Make any modification, adaptation, improvement, enhancement, translation, copy, or derivative work from the application or Service.</li>
                    <li>Violate any applicable laws, rules, or regulations in connection with your access to or use of the application or Service.</li>
                    <li>Remove, alter, or obscure any proprietary notice (including any copyright or trademark notice) posted by us or the licensors of the application, content, or Service.</li>
                    <li>Use the application, content, or Service for any revenue-generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended.</li>
                    <li>Make the application or Service available on a network or other environment that allows access or use by multiple devices or users simultaneously.</li>
                    <li>Use the application, content, or Service to create a product, service, or software that is, directly or indirectly, competitive with or serves as a substitute for the application.</li>
                    <li>Use the application or Service to send automated queries to any website or to send any unsolicited commercial emails.</li>
                    <li>Use any proprietary information or any of our interfaces or other intellectual property in the design, development, manufacturing, licensing, or distribution of any applications, accessories, or devices for use with the application or Service.</li>
                </ul>
            </section>

            <section>
                <h2>Services Provided</h2>
                <p>
                    The Service is an intermediary service connecting users with cryptocurrencies made available through other
                    third-party networks that we do not own or control. You are invited to connect all your third-party
                    wallets and exchanges to the Service and create a wallet provided by a third party, which may allow you to
                    buy, sell, exchange, track, and profit from cryptocurrencies from your own interface or network, which we
                    do not own or control.
                </p>
                <p>
                    The Service is not a platform where you can buy, sell, or trade cryptocurrencies or digital assets, but the
                    Service is only an intermediary to connect you to other third-party services.
                </p>
                <p>
                    The Services should not be considered as investment, financial, and/or business advice, and you should not
                    regard any of its content as such. We recommend seeking legal and financial guidance before starting to
                    buy, trade, or sell digital assets. We will not be responsible for the consequences of relying on any
                    opinion or statement contained herein or for any omission. Digital assets are volatile. You must be fully
                    aware of the level of risk involved before trading. Any loss of data, digital assets, or profit is your
                    sole responsibility. There is always the possibility of something unexpected happening with respect to
                    digital assets that causes the loss of your cryptocurrencies. We strongly recommend that you do not invest
                    more than you are willing to lose. You are solely responsible for any investment decisions.
                </p>
                <p>
                    Some information within the Services comes from publicly available sources or third parties and may change
                    from time to time without any requirement of prior notice. We do not guarantee the accuracy, precision, and
                    completeness of such information provided and will not be responsible for it.
                </p>
            </section>

            <section>
                <h2>Subscriptions</h2>
                <p>
                    We offer a free trial for new users who register with the Service. Your account will be charged according
                    to the chosen subscription at the end of the free trial. Some parts of the Service are charged based on a
                    subscription ("Subscription"). You will be charged in advance on a recurring and periodic billing cycle
                    ("Billing Cycle"). A Billing Cycle is defined as either monthly or annually, depending on the type of
                    subscription plan you select when purchasing a Subscription.
                </p>
                <p>
                    At the end of each Billing Cycle, your Subscription will automatically renew under the same conditions
                    unless you cancel it or we cancel it. You may cancel your Subscription at any time. A valid payment method
                    is required to process your Subscription payment. If the automatic billing fails for any reason, your
                    Subscription will be terminated.
                </p>
            </section>

            <section>
                <h2>Changes to Fees</h2>
                <p>
                    At our sole discretion and at any time, we may modify Subscription fees for any Subscription. Any changes
                    to Subscription fees will take effect at the end of the current Billing Cycle.
                </p>
                <p>
                    We will provide you with reasonable prior notice of any change to Subscription fees so that you have the
                    opportunity to terminate your Subscription before the change takes effect.
                </p>
                <p>
                    Your continued use of the Service after the change in Subscription fees takes effect constitutes your
                    agreement to pay the modified Subscription fee amount.
                </p>
            </section>

            <section>
                <h2>Accounts</h2>
                <p>
                    When you create an account with us, you must provide accurate, complete, and up-to-date information at all
                    times. Failure to do so constitutes a violation of the Terms, which may result in immediate termination of
                    your account and the Service.
                </p>
                <p>
                    You are responsible for safeguarding the password you use to access the Service and for any activity or
                    action under your password, whether your password is with us or with a third-party service.
                </p>
                <p>
                    You agree not to disclose your password to any third party. You must notify us immediately upon becoming
                    aware of any security breach or unauthorized use of your account. By creating an account, you agree that you
                    will be solely responsible for all activity that occurs under your account.
                </p>
            </section>

            <section>
                <h2>Electronic Communication</h2>
                <p>
                    We may be required to provide you with certain legal and regulatory disclosures, periodic statements and
                    confirmations, notices, tax forms, and other communications (collectively "Communications") in written
                    format. By agreeing to these Terms, you consent to receive such Communications in electronic format. Consent
                    to electronic delivery applies to each year in which Communications are provided. If you no longer have
                    access to your account to receive Communications in electronic format, you may request the Communication in
                    written format. You agree that all terms and conditions, agreements, notices, disclosures, and other
                    communications we provide to you electronically satisfy any legal requirement that such communications would
                    meet if provided in writing. With respect to these Terms, you waive any rights to require an original
                    (non-electronic) signature or delivery or retention of non-electronic records, to the extent that such
                    waiver is not prohibited by applicable law.
                </p>
            </section>

            <section>
                <h2>Intellectual Property</h2>
                <p>
                    The Service and its content, features, and functionalities are and will remain our exclusive property. The
                    Service is protected by copyrights, trademarks, and other laws of Canada and foreign countries. Our
                    trademarks and trade dress may not be used in connection with any product or service without our prior
                    written consent.
                </p>
            </section>

            <section>
                <h2>User Representations</h2>
                <p>
                    By using the Service, you represent and warrant that:
                </p>
                <ul>
                    <li>All registration information you submit will be true, accurate, current, and complete.</li>
                    <li>You will maintain the accuracy of such information and promptly update such registration information as necessary.</li>
                    <li>You have legal capacity and agree to comply with these Terms.</li>
                    <li>You are not a minor in the jurisdiction in which you reside or have obtained parental permission to use the Service.</li>
                    <li>You will not access the Service through automated or non-human means, whether through a bot, script, or otherwise.</li>
                    <li>You will not use the Service for any illegal or unauthorized purpose.</li>
                    <li>Your use of the Service will not violate any applicable law or regulation.</li>
                </ul>
            </section>

            <section>
                <h2>Third-Party Content</h2>
                <p>
                    The Service may contain (or you may be directed through the Service to) links to other websites ("Third-Party Sites"), as well as articles, photographs, texts, graphics, images, designs, music, sounds, videos, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content"). Such Third-Party Content and Third-Party Sites are not investigated, monitored, or checked for accuracy, adequacy, or completeness by us, and we are not responsible for any Third-Party Site or any Third-Party Content posted, available, or installed from the Service, including the content, accuracy, offense, opinions, privacy practices, or other policies of the Third-Party Sites or Third-Party Content.
                </p>
            </section>

            <section>
                <h2>Privacy</h2>
                <p>
                    The privacy of your information is important to us. Please review our Privacy Policy.
                </p>
            </section>

            <section>
                <h2>Disclaimer</h2>
                <p>
                    Be aware that the use of the Service may involve the risk of financial or physical loss. We assume no
                    responsibility for any loss or damage, whether direct or indirect, arising from the use of the Service or
                    transactions conducted through the Service. We appreciate your understanding and advise caution when using
                    the Service.
                </p>
            </section>

            <section>
                <h2>Limitation of Liability</h2>
                <p>
                    To the fullest extent permitted by law, we will not be liable for any loss or damage, whether direct,
                    indirect, incidental, special, consequential, or punitive, arising from the use or inability to use the
                    Service or any Third-Party Content, whether based on warranty, contract, tort (including negligence), or any
                    other legal theory, and whether or not we have been informed of the possibility of such damages.
                </p>
            </section>

            <section>
                <h2>Governing Law</h2>
                <p>
                    These Terms will be governed and construed in accordance with the laws of Quebec, Canada, without regard to
                    its conflict of law provisions.
                </p>
            </section>

            <section>
                <h2>Dispute Resolution</h2>
                <p>
                    Any dispute or controversy related to these Terms or the use of the Service will be resolved through good
                    faith negotiation between the parties. If the dispute is not resolved through negotiation, the parties agree
                    to attempt mediation or arbitration to resolve the controversy.
                </p>
            </section>

            <section>
                <h2>Termination</h2>
                <p>
                    We may terminate or suspend your account and access to the Service immediately, without prior notice or
                    liability, for any reason, including, without limitation, if you violate these Terms.
                </p>
            </section>

            <section>
                <h2>Changes to Terms and Conditions</h2>
                <p>
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision
                    is material, we will provide at least 30 days' notice prior to any new Terms taking effect. What constitutes
                    a material change will be determined at our sole discretion.
                </p>
            </section>

            <section>
                <h2>Contact Us</h2>
                <p>
                    If you have any questions about these Terms and Conditions, please contact us.
                </p>
            </section>
        </div>
    )

}

export default termsOfService;