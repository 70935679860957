import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import style from './css/CryptoInfo.module.css';
import {getApiCriptoData} from '../utils/Api'

const CryptoInfo = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 50;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(getApiCriptoData());
        setCryptoData(response.data.data);
      } catch (error) {
        console.error('Erro carregar informações crypto', error);
      }
    };

    fetchData();
  }, []);

  const getClassForValue = (value) => {
    return value > 0 ? style.positive : value < 0 ? style.negative : '';
  };

  const filteredCryptoData = cryptoData.filter(crypto =>
    crypto.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    crypto.symbol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentCryptoData = filteredCryptoData.slice(0, currentPage * itemsPerPage);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 50 && !isLoading) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
        setIsLoading(false);
      }, 500);
    }
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={style.cryptoinfocontainer}>
      <h1>Crypto Market Data</h1>
      <input
        type="text"
        placeholder="Search for a crypto..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={style.searchBar}
      />
      <div className={style.cryptolist}>
        {currentCryptoData.map((crypto, index) => (
          <div key={crypto.id} className={`${style.cryptoitem} ${currentCryptoData.length === 1 ? style.single : ''}`}>
            <img src={crypto.icon_32} alt={`${crypto.name} icon`} />
            <h2>{crypto.name} ({crypto.symbol})</h2>
            <p>Current Price: ${parseFloat(crypto.current_price).toFixed(2)}</p>
            <p className={getClassForValue(crypto.price_change_percent_1m)}>Price (1m): {parseFloat(crypto.price_change_percent_1m).toFixed(2)}%</p>
            <p className={getClassForValue(crypto.price_change_percent_15m)}>Price (15m): {parseFloat(crypto.price_change_percent_15m).toFixed(2)}%</p>
            <p className={getClassForValue(crypto.price_change_percent_1h)}>Price (1h): {parseFloat(crypto.price_change_percent_1h).toFixed(2)}%</p>
            <p className={getClassForValue(crypto.price_change_percent_7h)}>Price (7h): {parseFloat(crypto.price_change_percent_7h).toFixed(2)}%</p>
            <p className={getClassForValue(crypto.price_change_percent_24h)}>Price (24h): {parseFloat(crypto.price_change_percent_24h).toFixed(2)}%</p>
            <p className={getClassForValue(crypto.price_change_percent_7d)}>Price (7d): {parseFloat(crypto.price_change_percent_7d).toFixed(2)}%</p>
            <p className={getClassForValue(crypto.movement_last_1D)}>Movement Last 1D: ${parseFloat(crypto.movement_last_1D).toFixed(2)}</p>
            <p className={getClassForValue(crypto.probability)}>Probability: {parseFloat(crypto.probability).toFixed(2)}%</p>
          </div>
        ))}
      </div>
      {isLoading && <p>Loading more items...</p>}
    </div>
  );
};

export default CryptoInfo;
