import React, { useEffect, useState } from 'react';
import './css/TypingEffect.css';

const TypingEffect = ({ text, typingSpeed = 50, delay = 10000 }) => {
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    if (!text) return;

    let index = 0;
    let intervalId;

    const startTyping = () => {
      intervalId = setInterval(() => {
        setDisplayText((prev) => prev + text.charAt(index));
        index += 1;
        if (index >= text.length) {
          clearInterval(intervalId);
          setTimeout(() => {
            setIsTyping(false); // Indica que a digitação foi concluída e permite o reset
          }, delay);
        }
      }, typingSpeed);
    };

    if (isTyping) {
      startTyping();
    } else {
      setDisplayText('');
      setIsTyping(true);
    }

    return () => clearInterval(intervalId);
  }, [text, isTyping, typingSpeed, delay]);

  return (
    <div className="typing-effect">
      {displayText}
    </div>
  );
};

export default TypingEffect;
