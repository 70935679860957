import '../css/Blog.module.css'
import CookieConsentModal from '../components/CookieConsentModal';

function Blog() {
    return (
        <div>
            <header>
                <h1>Crypto Simulator AI Blog</h1>
            </header>

            <nav>
                <ul>
                    <li><a href="#what-is-cryptocurrency">What is Cryptocurrency?</a></li>
                    <li><a href="#financial-market">How Does the Cryptocurrency Financial Market Work?</a></li>
                    <li><a href="#ai-and-cryptocurrencies">Artificial Intelligence and Cryptocurrencies</a></li>
                    <li><a href="#top-cryptocurrencies">Top Cryptocurrencies</a></li>
                </ul>
            </nav>

            <section id="what-is-cryptocurrency">
                <article>
                    <header>
                        <h2>What is Cryptocurrency?</h2>
                    </header>
                    <p>Cryptocurrency is a type of digital or virtual currency that uses cryptography for securing transactions. Unlike traditional currencies like the real or the dollar, cryptocurrencies are decentralized and operate on a network called blockchain.</p>
                </article>
            </section>

            <section id="financial-market">
                <article>
                    <header>
                        <h2>How Does the Cryptocurrency Financial Market Work?</h2>
                    </header>
                    <p>The cryptocurrency financial market is extremely volatile and operates differently from the traditional market. Transactions are conducted directly between users, without the need for intermediaries like banks. Cryptocurrencies can be bought and sold on various trading platforms, known as exchanges.</p>
                </article>
            </section>

            <section id="ai-and-cryptocurrencies">
                <article>
                    <header>
                        <h2>How Can Artificial Intelligence Help in the Cryptocurrency Market?</h2>
                    </header>
                    <p>Artificial intelligence (AI) can be a powerful tool in the cryptocurrency market. It can be used to predict market trends, automate trades, identify patterns in large volumes of data, and assist in fraud detection. Machine learning algorithms can analyze price histories and transaction volumes to make more accurate predictions.</p>
                </article>
            </section>

            <section id="top-cryptocurrencies">
                <article>
                    <header>
                        <h2>Top Cryptocurrencies in the Current Market</h2>
                    </header>
                    <p>
                        <strong>Bitcoin (BTC):</strong> The first and most well-known cryptocurrency, created in 2009 by an individual or group using the pseudonym Satoshi Nakamoto.<br />
                        <strong>Ethereum (ETH):</strong> Known for its smart contract functionality, it was created in 2015 by Vitalik Buterin.<br />
                        <strong>Binance Coin (BNB):</strong> A cryptocurrency created by Binance, one of the largest cryptocurrency exchanges in the world.<br />
                        <strong>Cardano (ADA):</strong> A blockchain platform focused on security and scalability, created by Charles Hoskinson.<br />
                        <strong>Ripple (XRP):</strong> Known for its digital payment system, which enables fast and cheap transactions.<br />
                    </p>
                </article>
            </section>

            <section id="Curiosities-about-Cryptocurrencies">
                <article>
                    <header>
                        <h2>Curiosities about Cryptocurrencies</h2>
                    </header>
                    <p>The term "cryptocurrency" derives from cryptography, the technique that ensures the security and integrity of transactions.</p>
                    <p>The first commercial use of Bitcoin was to buy two pizzas in 2010, a transaction that became famous as "Bitcoin Pizza Day".</p>
                </article>
            </section>

            <footer>
                <p>&copy; 2024 Blog about Cryptocurrencies. All rights reserved.</p>
            </footer>

            <CookieConsentModal />
        </div>
    );
}

export default Blog;
