const IMAGES = {
    qrcode : require('../img/qrCode_Crypto.png'),
    App1 : require('../img/App1.png'),
    App2 : require('../img/App2.png'),
    App3 : require('../img/App3.png'),
    App4 : require('../img/App4.png'),
    App5 : require('../img/App5.png'),
    App6 : require('../img/App6.png'),
    App7 : require('../img/App7.png'),
}

export default IMAGES;