import styles from '../css/Download.module.css'
import Panel from '../components/Panel'
import qrcode from '../img/qrCode_Crypto.png'
import CookieConsentModal from '../components/CookieConsentModal';

function Download() {
    return (
        <div className={styles.home_container}>
            <div className={styles.panel_container}>
                <Panel title="Simulations with Artificial Intelligence">
                    <p> Applying Artificial Intelligence in Cryptocurrency Markets.</p>
                </Panel>
                <Panel title="Cryptocurrency market notifications">
                    <p> Stay up to date with the cryptocurrency market with notifications on your cell phone.</p>
                </Panel>
            </div>
            <h1 className={styles.text_center}>
            Available for download soon
            </h1>
            <div className={styles.container_img}>
            <img className={styles.img_home} src={qrcode} alt="qrCode" width="200" ></img>
            <CookieConsentModal />
            </div>
            
        </div>
    )
}

export default Download