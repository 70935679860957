import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import styles from '../css/Footer.module.css'
import { FaXTwitter } from 'react-icons/fa6';
function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className={styles.footer}>
            <ul className={styles.social_list}>
                <li><FaXTwitter/></li>
                <li ><FaFacebook /></li>
                <li><FaInstagram /></li>
                <li><FaLinkedin /></li>
            </ul>
            <p>
                <span className={styles.copy_right}> Crypto Simulator AI &copy; {currentYear}</span>
            </p>
        </footer>
    )
}
export default Footer