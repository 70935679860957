import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import style from './css/CookieConsentModal.module.css';

const CookieConsentModal = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const cookiesAccepted = Cookies.get('cookiesAccepted');
        if (!cookiesAccepted) {
            setShowModal(true);
        }
    }, []);

    const handleAcceptCookies = () => {
        Cookies.set('cookiesAccepted', 'true', { expires: 365, sameSite: 'Lax' });
        setShowModal(false);
    };

    const handleDeclineCookies = () => {
        Cookies.set('cookiesAccepted', 'false', { expires: 365, sameSite: 'Lax' });
        setShowModal(false);
    };

    if (!showModal) {
        return null;
    }

    return (
        <div className={style.modal_overlay}>
            <div className={style.modal_content}>
                <h2>Cookies Policy</h2>
                <span>This website uses cookies to ensure you get the best experience on our website. <a href="/privacy-police" target="_blank" rel="noopener noreferrer"> Learn more </a></span>
                <div className={style.modal_buttons}>
                    <button onClick={handleAcceptCookies}>Accept</button>
                    <button onClick={handleDeclineCookies}>Decline</button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsentModal;
