import React from 'react';
import { Link } from 'react-router-dom';
import Container from './Container';
import styles from '../css/Navbar.module.css';
import logo from '../../img/logo.png';
import videoFile from  '../../media/Crypto_V1.mkv'

function Navbar() {
    return (
        <nav className={styles.navbar}>
            <Container className={styles.container}>
                <Link to="/">
                    <video className={styles.logo} autoPlay loop muted>
                        <source src={videoFile} type="video/mp4" />
                    </video>
                </Link>
                <h1 className={styles.title}>Crypto Simulator AI</h1>
                <ul className={styles.list}>
                    <li className={styles.item}>
                        <Link to="/market">Market</Link>
                    </li>
                    <li className={styles.item}>
                        <Link to="/download">Download</Link>
                    </li>
                    <li className={styles.item}>
                        <Link to="/blog">Blog</Link>
                    </li>
                    <li className={styles.item}>
                        <Link to="/privacy-police">Privacy Policy</Link>
                    </li>
                    <li className={styles.item}>
                        <Link to="/terms-of-service">Terms Of Service</Link>
                    </li>
                </ul>
                <div className={styles.buttons}>
                    <Link to="/login" className={`${styles.button} ${styles.login}`}>Log In</Link>
                    {/*<Link to="/register" className={`${styles.button} ${styles.register}`}>Sign Up</Link>*/}
                </div>

            </Container>
        </nav>
    );
}

export default Navbar;
