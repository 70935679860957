import styles from '../css/Contact.module.css'

function Contact() {
  const currentYear = new Date().getFullYear();
    return (
        <>
            <div>
                <h1 className={styles.copy_right}>Under development</h1>
                <span className={styles.copy_right}> Crypto Simulator AI &copy; {currentYear}</span>
            </div>
        </>
    )

}

export default Contact