import React, { useState } from 'react';
import OpenAI from 'openai';
import styles from '../css/Chat.module.css'

const openai = new OpenAI({ apiKey: 'sk-proj-ZfeyK7b3K2QZNXj13tNkT3BlbkFJDorliFwy1f8NsGVc6qhN',   dangerouslyAllowBrowser: true, });

function ChatAI() {
    const [input, setInput] = useState('');
    const [response, setResponse] = useState('');

    // Função para enviar a mensagem
    const sendMessage = async () => {
        try {
            const completion = await openai.chat.completions.create({
                model: 'gpt-3.5-turbo',
                prompt: input,
                max_tokens: 150,
            });
            
            setResponse(completion.data.choices[0].text);
        } catch (error) {
            console.error(error);
            setResponse('Erro ao se comunicar com a API.');
        }
    };

    return (
        <div className={styles.container}>
            <textarea                 value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Digite sua mensagem aqui..."
            />
            <button onClick={sendMessage}>Enviar</button>
                <h3>Resposta:</h3>
                <p>{response}</p>
        </div>
    );
}

export default ChatAI;