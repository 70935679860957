import React from 'react';
import styles from './css/Panel.module.css'; // Importe o arquivo de estilos para estilizar o painel

function Panel(props) {
  return (
    <>
    <div className={styles.panel}>
      <div className={styles.panel_header}>
        <h2>{props.title}</h2>
      </div>
      <div className={styles.panel_content}>
        {props.children}
      </div>
    </div>
    </>
  );
}

export default Panel;