import React from 'react';

const DOMAIN = 'https://cryptosimulatorai.com';
const DOMAIN_API = '/api/public/api';
const METHOD_LOGIN = '/login';
const METHOD_GET_PUBLIC_CRIPTO_DATA = '/web/crypto-data';

function getDomain() {
    return DOMAIN;
}

function getUrlApi() {
    return DOMAIN + DOMAIN_API;
}

function  getApiCriptoData(){
    return getUrlApi() + METHOD_GET_PUBLIC_CRIPTO_DATA;
}

function  getApiLogin(){
    return getUrlApi() + METHOD_LOGIN;
}

export {getApiCriptoData, getApiLogin };
